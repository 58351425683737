import React, { useContext } from "react";
import { Context } from "redux/Store";
import { getUrlFromSlug } from "libs/services/utils";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA, TypeC } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/body/damaged-skin/aquaphor-healing-ointments-front.webp";
import backImage from "images/body/damaged-skin/aquaphor-healing-ointments-back.webp";
import frontZoomImage from "images/body/damaged-skin/aquaphor-healing-ointments-front-zoom.webp";
import backZoomImage from "images/body/damaged-skin/aquaphor-healing-ointments-back-zoom.webp";

import img2 from "images/body/damaged-skin/chart-aqupahor-ointment-hydration-improvement.webp";
import img3 from "images/body/damaged-skin/chart-aqupahor-ointment-hydration-improvement-palms.webp";
import img4 from "images/body/damaged-skin/chart-aqupahor-healing-ointment.webp";
import img5 from "images/body/damaged-skin/chart-aqupahor-ointment-heel-skin-improvement.webp";
import img6 from "images/body/damaged-skin/chart-aqupahor-target-areas.webp";
import img7 from "images/callouts/section-cout-resource.webp";
import img8 from "images/callouts/sunproducts2x.webp";
import img9 from "images/callouts/cout-footer-dry-skin-therapy.webp";

function AquaphorDryCrackedSkinPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useGoToAnchor();

  return (
    <Layout
      pageClass="page-body-damaged-dry-cracked-skin page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Aquaphor Healing Oinment: Dry, Cracked Skin"
        pageDescription="Learn about Aquaphor Healing Ointment for dry, cracked skin"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#003E7E"
              categoryName="Dry, cracked skin"
              categoryFontColor="#ffffff"
              productName="Aquaphor Healing Ointment<sup>®</sup>: Dry, Cracked Skin"
              productSlider={[frontImage, backImage]}
              zoomImages={[frontZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold blue-text sub-title">
                  Helps heal dry, cracked skin from head to toe
                </p>
                <p>
                  <span className="font-semi-bold">
                    Dry, cracked skin of the heels, hands, elbows, knees, and
                    lips can be difficult to treat.
                  </span>{" "}
                  Recommend Aquaphor Healing Ointment for targeted treatment.
                </p>
                <ul className="blue-bullets">
                  <li>
                    <span>
                      Humectant-rich formula helps moisturize, nourish, and
                      protect the skin to enhance healing
                    </span>
                  </li>
                  <li>
                    <span className="font-semi-bold">
                      Clinically proven to help heal dry, cracked hands and
                      heels with a complete formulation that contributes to the
                      overall efficacy
                    </span>
                  </li>
                  <li>
                    <span>
                      Clinically proven to help repair a compromised skin
                      barrier—restoring smooth, healthy skin
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold blue-text sub-title">
                  Active ingredient
                </p>
                <p className="font-semi-bold">
                  41% petrolatum (skin protectant)
                </p>
                <ul className="blue-bullets">
                  <li>
                    <span>Protects exposed skin surfaces</span>
                  </li>
                  <li>
                    <span>Locks in moisture</span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients no-brd">
                <p className="font-semi-bold blue-text sub-title">
                  Key ingredients
                </p>
                <Row>
                  <Col sm={12} md={6}>
                    <p className="font-semi-bold">Panthenol</p>
                    <ul className="blue-bullets">
                      <li>
                        <span>Softens and conditions skin</span>
                      </li>
                      <li>
                        <span>Binds water to support moisture</span>
                      </li>
                    </ul>
                    <p className="font-semi-bold">Lanolin alcohol</p>
                    <ul className="blue-bullets">
                      <li>
                        <span>Supports barrier repair</span>
                      </li>
                      <li>
                        <span>Defends against moisture loss</span>
                      </li>
                    </ul>
                  </Col>
                  <Col sm={12} md={6}>
                    <p className="font-semi-bold">Glycerin</p>
                    <ul className="blue-bullets">
                      <li>
                        <span>Binds water</span>
                      </li>
                      <li>
                        <span>Replenishes moisture reservoir</span>
                      </li>
                    </ul>
                    <p className="font-semi-bold">Bisabolol</p>
                    <ul className="blue-bullets">
                      <li>
                        <span>Soothes dry, irritated skin</span>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold blue-text sub-title">
                    Formulated for dry, cracked skin
                  </p>
                  <ul className="checkmarks blue">
                    <li>
                      <span>hypoallergenic</span>
                    </li>
                    <li>
                      <span>non-comedogenic</span>
                    </li>
                    <li>
                      <span>preservative-free</span>
                    </li>
                    <li>
                      <span>Fragrance-free</span>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </section>
        </div>
        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="HYDRATION IMPROVEMENT"
              productThemeColor="eucerin-blue"
              id="hydrationimprovement"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img2}
                    className="accordion-chart"
                    alt="Graph showing improvement in skin hydration in 1 day"
                  />
                  <img
                    src={img3}
                    className="accordion-chart"
                    alt="Visible improvement in skin hydration in 1 day"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Study to
                    assess the efficacy of Aquaphor Healing Ointment in
                    producing overnight improvements in the skin condition of
                    subjects with visibly dry and damaged skin on their hands.
                    Once-daily nightly application to one hand for 7 days
                    (N=17).
                  </p>
                  <p className="references-symbols double">
                    **Significant difference compared to baseline (
                    <span className="font-italic">p&lt;</span>0.001).
                  </p>
                  <p className="references-symbols">
                    <sup>&dagger;</sup>Significant difference compared to
                    untreated control (
                    <span className="font-italic">p&lt;</span>0.05).
                  </p>
                  <p className="references-symbols last">
                    <sup>&Dagger;</sup>Significant difference compared to
                    untreated control (
                    <span className="font-italic">p&lt;</span>0.001).
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    <p className="spacer">
                      33% improvement
                      <br className="show-desktop" /> in skin hydration
                      <br className="show-desktop" /> at Day 1
                    </p>
                    <p className="spacer">
                      95% improvement
                      <br className="show-desktop" /> in skin hydration
                      <br className="show-desktop" /> at Day 5
                    </p>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="HEEL SKIN IMPROVEMENT"
              productThemeColor="eucerin-blue"
              id="heelskinimprovement"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img4}
                    className="accordion-chart"
                    alt="Chart showing improvement in heel skin from baseline to week 4"
                  />
                  <img
                    src={img5}
                    className="accordion-chart"
                    alt="Visual showing improvement in heel skin  after 1 weeek"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Study to
                    assess the efficacy of Aquaphor Healing Ointment when
                    applied to dry, rough, cracked heels once nightly for 4
                    weeks (N=47).
                  </p>
                  <p className="references-symbols double last">
                    **Statistically significant agreement vs disagreement (
                    <span className="font-italic">p&lt;</span>0.0001).
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Improvements in
                    <br className="show-desktop" /> scaling, cracking,
                    <br className="show-desktop" /> and roughness of
                    <br className="show-desktop" /> heels in just{" "}
                    <nobr>1 week</nobr>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="Treatment areas"
              productThemeColor="eucerin-blue"
              id="treatmentareas"
            >
              <Row>
                <Col sm={12} md={8}>
                  <img
                    src={img6}
                    className="accordion-chart"
                    alt="Showing Head-to-toe versatility for dry, cracked skin"
                  />
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
              </Row>
            </AccordionContent>
          </section>
          <section className="callouts-container gradient-bg section-support-resource">
            <div className="inner-centered-container">
              <Row>
                <Col sm={12} md={8} order={{ xs: 2, md: 1 }} className="">
                  <TypeC
                    buttonText="DOWNLOAD HERE"
                    buttonUrl={getUrlFromSlug(
                      "aquaphor-patient-brochure",
                      state?.sanityData?.allResources
                    )}
                    className="center-v"
                    isExternalLink={true}
                    targetOpen="_blank"
                  >
                    <p className="reg-text-big">
                      Support your recommendation
                      <br className="show-desktop" /> with this resource
                    </p>
                    <p className="reg-text">
                      Patients will appreciate reviewing the versatility of
                      Aquaphor Healing
                      <br className="show-desktop" /> Ointment—so many uses from
                      head to toe!
                    </p>
                  </TypeC>
                </Col>
                <Col sm={12} md={4} order={{ xs: 1, md: 2 }} className="">
                  <img
                    src={img7}
                    alt="Versatility of Aquaphor Healing Ointment and uses"
                    className="cout-product-img"
                  />
                </Col>
              </Row>
            </div>
          </section>
          <section className="callouts-container gradient-bg blue-grad">
            <div className="inner-centered-container">
              <Row>
                <Col sm={12} md={6}>
                  <TypeA
                    imgRef={img8}
                    imgAlt="DISCOVER EUCERIN® SUN"
                    caption="Sun protection for all skin types<br class='show-desktop' /> and tones"
                    buttonText="DISCOVER EUCERIN<sup>®</sup> SUN"
                    buttonUrl="/sun"
                    imgStyles={{ height: 165 }}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <TypeA
                    imgRef={img9}
                    imgAlt="Dry Skin Therapy"
                    caption="Recommend long-lasting<br class='show-desktop' /> hydration for patients’ dry skin"
                    buttonText="Dry Skin Therapy"
                    buttonUrl="/body/dryskin"
                    imgStyles={{ height: 165 }}
                  />
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export default AquaphorDryCrackedSkinPage;
